export const CATEGORY_SAVE_REQUEST = "CATEGORY_SAVE_REQUEST";
export const CATEGORY_SAVE_SUCCESS = "CATEGORY_SAVE_SUCCESS";
export const CATEGORY_SAVE_FAIL = "CATEGORY_SAVE_FAIL";

export const CATEGORY_REMOVE_REQUEST = "CATEGORY_REMOVE_REQUEST";
export const CATEGORY_REMOVE_SUCCESS = "CATEGORY_REMOVE_SUCCESS";
export const CATEGORY_REMOVE_FAIL = "CATEGORY_REMOVE_FAIL";

export const CITY_SAVE_REQUEST = "CITY_SAVE_REQUEST";
export const CITY_SAVE_SUCCESS = "CITY_SAVE_SUCCESS";
export const CITY_SAVE_FAIL = "CITY_SAVE_FAIL";

export const CITY_REMOVE_REQUEST = "CITY_REMOVE_REQUEST";
export const CITY_REMOVE_SUCCESS = "CITY_REMOVE_SUCCESS";
export const CITY_REMOVE_FAIL = "CITY_REMOVE_FAIL";

export const AREA_SAVE_REQUEST = "AREA_SAVE_REQUEST";
export const AREA_SAVE_SUCCESS = "AREA_SAVE_SUCCESS";
export const AREA_SAVE_FAIL = "AREA_SAVE_FAIL";

export const AREA_REMOVE_REQUEST = "AREA_REMOVE_REQUEST";
export const AREA_REMOVE_SUCCESS = "AREA_REMOVE_SUCCESS";
export const AREA_REMOVE_FAIL = "AREA_REMOVE_FAIL";


export const INQUIRY_REQUEST = "INQUIRY_REQUEST";
export const INQUIRY_SUCCESS = "INQUIRY_SUCCESS";
export const INQUIRY_FAIL = "INQUIRY_FAIL";

export const COMPLETE_INQUIRY_REQUEST = "COMPLETE_INQUIRY_REQUEST";
export const COMPLETE_INQUIRY_SUCCESS = "COMPLETE_INQUIRY_SUCCESS";
export const COMPLETE_INQUIRY_FAIL = "COMPLETE_INQUIRY_FAIL";

export const CREATE_PROPERTY_REQUEST = "CREATE_PROPERTY_REQUEST";
export const CREATE_PROPERTY_SUCCESS = "CREATE_PROPERTY_SUCCESS";
export const CREATE_PROPERTY_FAIL = "CREATE_PROPERTY_FAIL";


export const UPDATE_PROPERTY_REQUEST = "UPDATE_PROPERTY_REQUEST";
export const UPDATE_PROPERTY_SUCCESS = "UPDATE_PROPERTY_SUCCESS";
export const UPDATE_PROPERTY_FAIL = "UPDATE_PROPERTY_FAIL";

export const UPDATE_ADMIN_REQUEST = "UPDATE_ADMIN_REQUEST";
export const UPDATE_ADMIN_SUCCESS = "UPDATE_ADMIN_SUCCESS";
export const UPDATE_ADMIN_FAIL = "UPDATE_ADMIN_FAIL";