import { LOGIN_FAIL, LOGIN_REQUEST, LOGIN_SUCCESS } from "../constants/loginConstant"

const initialState = {loading:false}
const loginReducer = (state = initialState,action)=>{
    switch(action.type)
    {
        case LOGIN_REQUEST:
        {
            return {
                ...state,
                loading:true,
            }
        }
        case LOGIN_SUCCESS:
        {
            return {
                ...state,
                loading:false,
                message : action.payload
            }
        }
        case LOGIN_FAIL:
        {
            return{
                ...state,
                loading:false,
                error : action.payload
            }
        }
        default:
        {
            return {
                ...state,
                loading:false,
            }
        }
    }
}

export default loginReducer;