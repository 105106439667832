import { useSelector } from "react-redux"
import { Navigate, Outlet } from "react-router-dom";
import Loading from "../components/Loading/Loading";

const ProtectedRoute = ()=>{
    const {status} = useSelector((state)=>state.user);
    const {logoutLoading,loading} = useSelector(state=>state.user);
    return (logoutLoading || loading)?<Loading /> :  (status?<Outlet />:<Navigate to='/login'/>);
}

export default ProtectedRoute;