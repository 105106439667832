import { useSelector } from "react-redux";
import CustomFadeLoader from "../../../components/Loader/CustomFadeLoader";

const Features = ()=>{
    const categories = useSelector(state=>state.categories);
    const features = {
        FLAT : ["Children Play Area" ,"24 Hours Security" ,"24 Hours Water supply" ,"Elevator" ,"Gazebo" ,"CCTV Camera" ,"CLASS ENTRANCE","SCHOOLBUS DROP,OFF,ZONE","ENTRANCEFOYER","INDOOR GAMES","MULTIPURPOSE Hall","FIRE SAFETY SYSTEM"],
        BUNGLOW : ["24 * 7 Security" ,"24*7 Water Supply" ,"Car Parking Space" ,"Common Garden" ,"Senior Citizen Seating Area"],
        PLOT : ["Compound Wall","Gymnasium", "Library","Restaurant","Swimming Pool"],
    }  
    return <>
        <div className="d-flex justify-content-center align-items-center p-4 border-bottom">
            <h1>Features</h1>
        </div>
        <div className="d-flex flex-wrap" style={{height:"80vh",overflowY:"auto"}}>
            {categories.loading?<CustomFadeLoader />:
            categories.category.status && categories.category.message.map((value)=>
                <div className="p-1 my-2 col-md-4 col-sm-12 col-12">
                    <div className="card">
                        <div className="card-body bg-dark text-light">
                            <h3 className="card-title">{value.name}</h3>
                        </div>
                        <ul className="list-group list-group-flush">
                            {
                                 features[value.name] && features[value.name].map((v)=><li className="list-group-item fs-5">{v}</li>)
                            }
                        </ul>
                        <div className="card-body">

                            {/* <a href="#" className="card-link">Card link</a>
                            <a href="#" className="card-link">Another link</a> */}
                        </div>
                    </div>
                </div>)
            }
        </div>
    </>;
}

export default Features;