const { ALL_PROPERTY_REQUEST, ALL_PROPERTY_SUCCESS, ALL_PROPERTY_FAIL, PROPERTY_DETAILS_REQUEST, PROPERTY_DETAILS_SUCCESS, PROPERTY_DETAILS_FAIL } = require("../constants/propertyConstants")

const initialState = {property:[],price:[0,2000000],loading:true}

export const propertyReducer = (state = initialState,action)=>{
    switch(action.type)
    {
        case ALL_PROPERTY_REQUEST:
        {
            return {
                ...state,
                loading:true
            }
        }
        case ALL_PROPERTY_SUCCESS:
        {
            return {
                ...state,
                loading:false,
                property:action.payload
            }
        }
        case ALL_PROPERTY_FAIL:
        {
            return {
                ...state,
                loading:false,
                error:action.payload
            }
        }
        default:
            return{
                ...state
            }
    }
}

const initialPropertyState = {property : {}, loading:true}

export const propertyDetailsReducer = (state = initialPropertyState,action)=>{
    switch(action.type)
    {
        case PROPERTY_DETAILS_REQUEST:
        {
            return {
                ...state,
                loading:true,
            }
        }
        case PROPERTY_DETAILS_SUCCESS:
        {
            return {
                ...state,
                loading:false,
                property:action.payload
            }
        }
        case PROPERTY_DETAILS_FAIL:
        {
            return {
                ...state,
                loading:false,
                error:action.payload
            }
        }
        default:
            return{
                ...state
            }
    }
}
