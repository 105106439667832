import Header from "../../components/Header/header";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import {MdLocationPin} from "react-icons/md"
import { FaRupeeSign } from "react-icons/fa"
import "./style.css"
import "swiper/css";
import "swiper/css/navigation";
import "./PropertyDetails.css"
import InquiryForm from "../../components/InquiryForm/InquiryForm";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getPropertyDetails } from "../../actions/getProperties";
import { ToastContainer } from "react-toastify";
import Footer from "../../components/Footer/Footer";
import img_endpoint from "../../utils/img_endpoint";
import CustomHashLoader from "../../components/Loader/CustomHashLoader";
const PropertyDetails = ()=>{
    const propertyDetails = useSelector(state=>state.propertyDetails);
    const id = useParams().id;
    const cities = useSelector(state=>state.cities.cities)
    const categories = useSelector(state=>state.categories.category.message)
    const filterIds = (id,arr)=>{
      for(let i in arr)
      {
        if(arr[i]._id===id)
        {
          return arr[i].name
        }
      }
    }
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(getPropertyDetails(id))
    },[dispatch,id])
    return propertyDetails.error?<h1>{propertyDetails.error}</h1>:<>
        <div className="bg-img m-0">
            <Header />
        </div>
        {
            propertyDetails.loading?
            <CustomHashLoader />:
            <>
            <div className="d-flex flex-wrap">
                <div className="col-xl-7 col-12">
                    <Swiper navigation={true} modules={[Navigation]} className="mySwiper h-100">
                        {
                            propertyDetails.property.youtube?<SwiperSlide className="h-100">
                                <div className="embed-responsive embed-responsive-16by9 h-100">
                                    <iframe className="embed-responsive-item w-100 h-100" title={propertyDetails.property.name} src={propertyDetails.property.youtube} allowfullscreen></iframe>
                                </div>
                            </SwiperSlide>:""
                        }
                        {
                            propertyDetails.property.images?propertyDetails.property.images.map((value,key)=><SwiperSlide key={key}>
                                    <img src={`${img_endpoint}${value}`} className="img-fluid sliderContent" alt="" />
                                </SwiperSlide>):""
                        }
                        
                    </Swiper>
                </div>
                <div className="col-xl-5 col-12">
                        <>
                            <div className="schemeHeading m-4">
                                <h2 className="text-start pt-2 px-4">{propertyDetails.property.name}</h2>
                                <p className="fs-4 px-3 text-success d-flex align-items-center"><MdLocationPin/> {propertyDetails.property.area}, {filterIds(propertyDetails.property.city,cities)}</p>
                            </div>
                            <div className="d-flex">
                                <div className="col-md-5 col-6 px-4">
                                    <div className="my-4 border-bottom">
                                        <h4 className="loginHeader fs-5">Property Type</h4>
                                        <p className="fs-4 ps-3 prime-color">{filterIds(propertyDetails.property.category,categories)}</p>
                                    </div>
                                    <div className="my-4 border-bottom">
                                        <h4 className="loginHeader fs-5">Size</h4>
                                        <p className="fs-4 ps-3 prime-color">{propertyDetails.property.size} {propertyDetails.property.sizeUnit}</p>
                                    </div>
                                    <div className="my-4 border-bottom">
                                        <h4 className="loginHeader fs-5">Price</h4>
                                        <p className="fs-4 ps-3 text-success d-flex align-items-center justify-content-start">{propertyDetails.property.price.toLocaleString('en-IN')} <FaRupeeSign className="text-success" /></p>
                                    </div>
                                </div>
                                <div className="my-4 col-md-7 col-6 border-start px-4">
                                    <h4 className="loginHeader fs-5">Facilities</h4>
                                    <ul className="list-group px-3">
                                        {
                                            propertyDetails.property.facilities.map((facility)=><li className="list-group-item list-group-item-action text-nowrap fs-6 p-2">{facility}</li>)
                                        }
                                    </ul>
                                </div>
                            </div>
                        </>
                </div>
                <div className="m-4">
                    <h4>Description</h4>
                    <p className="text-muted">{propertyDetails.property.description}</p>
                </div>
            </div>
            <hr className="py-2" />
            <InquiryForm />
            </>
        }
        <ToastContainer className="w-auto"/>
        <Footer />
    </>
}

export default PropertyDetails;