import axios from "axios";
import { USER_FAIL, USER_REQUEST, USER_SUCCESS } from "../constants/loginConstant";
import endpoint from "../utils/endpoint"

const getUser = ()=>{
    return async (dispatch)=>{
        dispatch({type:USER_REQUEST});
        try
        {
            axios.defaults.withCredentials = true;
            const user = await axios.post(endpoint+"/api/satyam/admin/authUser",{ withCredentials: true });
            if(user.data.status)
            {
                dispatch({type:USER_SUCCESS,payload:user.data.message});
            }
            else
            {
                dispatch({type:USER_FAIL,payload:""});
            }
        }
        catch(e)
        {
            dispatch({type:USER_FAIL,payload:"Please Log in First !!"})
        }

    }
}

export default getUser;