import "./PropertyCard.css"
import { FaRupeeSign } from "react-icons/fa"
import {MdLocationPin} from "react-icons/md"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import img_endpoint from "../img_endpoint"
const PropertyCard = ({id,name,size,sizeUnit,category,area,city,price,image})=>{
    const cities = useSelector(state=>state.cities.cities)
    const categories = useSelector(state=>state.categories.category.message)
    const filterIds = (id,arr)=>{
      for(let i in arr)
      {
        if(arr[i]._id===id)
        {
          return arr[i].name
        }
      }
    }
    return <>
        <div className="card propertyCard">
          <img src={`${img_endpoint}${image}`} className="card-img-top" alt="..." />
          <div className="card-body">
            <h4 className="card-title my-1">{name}</h4>
            <div className="d-flex justify-content-around flex-wrap">
                <p className="card-text d-inline fs-5 my-1 col-12">{size} {sizeUnit} <span>{filterIds(category,categories)}</span></p>
                <h5 className="d-flex align-items-center my-1 col-12"> <MdLocationPin/>{area}, {filterIds(city,cities)}</h5>
            </div>
            <div className="d-flex align-items-center my-2 fs-5">
                <FaRupeeSign className="text-success" /><p className="card-text text-success">{price}</p>
            </div>
            <Link to={`/PropertyDetails/${id}`} className="btn btnLink d-block fs-5">Visit Now</Link>
          </div>
        </div>
    </>
}

export default PropertyCard;