import Header from "../../components/Header/header";
import SearchSection from "../../components/SearchSection/SearchSection";
import PropertyCard from "../../utils/Property/PropertyCard"
import FilterSection from "../../components/FilterSection/FilterSection"
import {useDispatch, useSelector} from "react-redux"
import "./Property.css"
import { useEffect, useState } from "react";
import { getAllProperties } from "../../actions/getProperties";
import { getCities } from "../../actions/getCities";
import Footer from "../../components/Footer/Footer";
import { Link, useParams } from "react-router-dom";
import CustomHashLoader from "../../components/Loader/CustomHashLoader";
import { BiErrorCircle } from "react-icons/bi";


const Property = ()=>{
    const dispatch = useDispatch();
    const properties = useSelector(state => state.properties);
    const cities = useSelector(state=>state.cities);
    const [search,setSearch] = useState("");
    const [category,setCategory] = useState([]);
    const [city,setCity] = useState(null);
    const [currentMin,setCurrentMin] = useState(0);
    const [currentMax,setCurrentMax] = useState(100000000);
    let page = useParams().page;
    page = page?page:1;
    useEffect(()=>{
        dispatch(getAllProperties({search,category,city,price:[currentMin,currentMax],page}));
        dispatch(getCities());
    },[dispatch,search,category,city,page,currentMax,currentMin])
    return <>
        <div className="bg-img m-0">
            <Header category={category} setCategory={setCategory}/>
            <h1 className="header text-light text-center display-4 m-0">
                Welcome To Satyam Property Consultancy
            </h1>    
        </div>
        <div className="d-flex properties col-12">
            <div className="d-none d-sm-none col-md-3 d-md-block">
                <FilterSection category={category} setCategory={setCategory} city={city} setCity={setCity} cities={cities} maxPrice={100000000} minPrice={0} setCurrentMax={setCurrentMax} setCurrentMin={setCurrentMin} />
            </div>
            <div className="col-12 col-md-9 border">
                <SearchSection search={search} setSearch={setSearch} category={category} setCategory={setCategory} city={city} setCity={setCity} cities={cities} maxPrice={100000000} minPrice={0} setCurrentMax={setCurrentMax} setCurrentMin={setCurrentMin} filters={true} />
                <div className="d-flex flex-wrap justify-content-center">                   
                    {
                        properties.loading?
                        <CustomHashLoader />
                        :
                        properties.property.properties &&
                        properties.property.properties.length>0?
                        properties.property.properties.map((property,i)=><PropertyCard id={property._id} key={i} name={property.name} size={property.size} sizeUnit={property.sizeUnit} category={property.category} area={property.area} city={property.city} price={property.price} image={property.images[0]} />):
                        <h1 className="py-4 d-flex align-items-center text-warning">
                            Property Not Found
                            <BiErrorCircle className="text-danger mx-2" />    
                        </h1>
                    }
                    
                </div>
                <nav className="d-flex justify-content-center">
                  <ul className="pagination">
                    <li className="page-item">
                        <Link to={`/property/${page-1>0?page-1:1}`} className="page-link" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                      </Link>
                    </li>
                    {
                       Math.ceil(properties.property.numberOfProperties/6)>0?(new Array(Math.ceil(properties.property.numberOfProperties/6)).fill(1)).map((key,value)=> <li key={key} className="page-item">
                            <Link to={`/property/${value+1}`} key={value} className={`page-link ${value+1===Number(page)?"active":""}`}>
                            <span aria-hidden="true">{value+1}</span>
                        </Link>
                        </li>):""
                    }
                    <li className="page-item">
                      <Link to={`/property/${page+1<Math.ceil(Number(properties.property.numberOfProperties)/6)?page+1:Math.ceil(Number(properties.property.numberOfProperties)/6)}`} className="page-link" href="#" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                      </Link>
                    </li>
                  </ul>
                </nav>
            </div>
        </div>
        <Footer />
    </>;
}

export default Property;
