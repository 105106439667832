import {HashLoader} from "react-spinners"

const CustomHashLoader = ()=>{
    return <HashLoader
                color="#36d7b7"
                cssOverride={{
                    margin: '2em 25em'
                }}
                size={250}
            />
}

export default CustomHashLoader;