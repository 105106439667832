import { useState } from "react";
import "./InquiryForm.css"
import { toast } from "react-toastify";
import validator from "validator"
import { useDispatch, useSelector } from "react-redux";
import inquiryFormSubmit from "../../actions/inquiryForm";
import { useParams } from "react-router-dom";
import CustomFadeLoader from "../Loader/CustomFadeLoader";

const InquiryForm = ()=>{
    const dispatch = useDispatch();
    const params = useParams();
    const inquiry = useSelector(state=>state.inquiry);
    const [fname,setFname] = useState("");
    const [lname,setLname] = useState("");
    const [email,setEmail] = useState("");
    const [contact,setContact] = useState("");
    const inputHandler = (e)=>{
        if(e.target.id==="fname")
        {
            setFname(e.target.value);
        }
        else if(e.target.id==="lname")
        {
            setLname(e.target.value);
        }
        else if(e.target.id==="email")
        {
            setEmail(e.target.value);
        }
        else if(e.target.id==="contact")
        {
            setContact(e.target.value);
        }
    }
    
    const inquiryFormHandler = (e)=>{
        e.preventDefault();
        let valid=true;
        if(!validator.isEmail(email))
        {
            toast.error(`Please Enter Valid Email`, {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
                valid=false;
        }
        if(!validator.isMobilePhone(contact,"en-IN"))
        {
            toast.error(`Please Enter Valid Mobile Number`, {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
                valid=false;
        }
        if(fname.length<=2)
        {
            toast.error(`First Name Cannot Be Shorter Thank 3 Characters`, {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
                valid=false;
        }
        if(lname.length<=2)
        {
            toast.error(`Last Name Cannot Be Shorter Thank 3 Characters`, {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
                valid=false;
        }
        if(valid)
        {
            dispatch(inquiryFormSubmit({fname,lname,id:params.id,email,contact}))
            setFname("");
            setLname("");
            setEmail("");
            setContact("");
        }
        
    }

    return <>
        <div className="inquiryHeading m-4">
            <h1 className="text-start py-2 px-4">Inquiry Form</h1>
        </div>
        {
        inquiry.loading?<CustomFadeLoader />:
        <div className="my-4">
            <form action="#" method="POST" className="col-10 mx-4" onSubmit={inquiryFormHandler}>
                <div className="mb-3 col-12">
                    <label htmlFor="fname" className="form-label">First Name<span className="text-danger">*</span></label>
                    <input type="fname" className="form-control" id="fname" value={fname} onChange={inputHandler} required/>
                </div>
                <div className="mb-3 col-12">
                    <label htmlFor="lname" className="form-label">Last Name<span className="text-danger">*</span></label>
                    <input type="lname" className="form-control" id="lname" value={lname} onChange={inputHandler} required/>
                </div>
                <div className="d-flex flex-wrap justify-content-between">
                    <div className="mb-3 col-12">
                        <label htmlFor="email" className="form-label">Email<span className="text-danger">*</span></label>
                        <input type="email" className="form-control" id="email" value={email} onChange={inputHandler} required/>
                    </div>
                </div>
                <div className="d-flex flex-wrap justify-content-between">
                    <div className="mb-3 col-12">
                        <label htmlFor="contact" className="form-label">Contact No.<span className="text-danger">*</span></label>
                        <input type="number" className="form-control" id="contact" value={contact} onChange={inputHandler} required/>
                    </div>
                </div>
              <button type="submit" className="btn submit-btn text-light px-4 py-2">Submit</button>
            </form>
        </div>
        }
    </>;
}

export default InquiryForm;