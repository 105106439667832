import axios from "axios"
import { LOGIN_FAIL, LOGIN_REQUEST, LOGIN_SUCCESS, USER_SUCCESS } from "../constants/loginConstant";
import endpoint from "../utils/endpoint"

const login =(email,password,toast,navigate)=>{
    return async (dispatch)=>{
        try
        {
            dispatch({type:LOGIN_REQUEST});
            axios.defaults.withCredentials = true
            const user = await axios.post(endpoint+"/api/satyam/admin/login",{email,password,prevUrl:window.location.pathname},{withCredentials: true});
            if(user)
            {
                dispatch({type:LOGIN_SUCCESS,payload:"Login Successful"});
                dispatch({type:USER_SUCCESS,payload:user.data.user});
            }
        }
        catch(e)
        {
            toast.error(e.response.data.message, {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
            dispatch({type:LOGIN_FAIL,payload:e.response.data.message});
        }
    }
}

export default login;