import { UPDATE_ADMIN_FAIL, UPDATE_ADMIN_REQUEST, UPDATE_ADMIN_SUCCESS } from "../constants/adminConstants"
import { LOGOUT_FAIL, LOGOUT_REQUEST, LOGOUT_SUCCESS, USER_FAIL, USER_REQUEST, USER_SUCCESS } from "../constants/loginConstant"

const initialState = {logoutLoading:false,status:false,loading:true}
const userReducer = (state = initialState,action)=>{
    switch(action.type)
    {
        case USER_REQUEST:
        case UPDATE_ADMIN_REQUEST:
        {
            return {
                ...state,
                logoutLoading:false,
                loading:true
            }
        }
        case LOGOUT_REQUEST:
        {
            return {
                ...state,
                logoutLoading:true,
                status:false
            }
        }
        case UPDATE_ADMIN_SUCCESS:
        case USER_SUCCESS:
        {
            return {
                ...state,
                user: action.payload,
                logoutLoading:false,
                status:true,
                loading:false
            }
        }
        case LOGOUT_SUCCESS:
        {
            return {
                ...initialState,
                alert: action.payload,
                status:false,
                loading:false
            }
        }
        case USER_FAIL:
        case LOGOUT_FAIL:
        {
            return{
                ...state,
                user : action.payload,
                status:false,
                loading:false
            }
        }
        case UPDATE_ADMIN_FAIL:
        {
            return{
                ...state,
                loading:false
            }
        }
        default:
        {
            return {
                ...state,
            }
        }
    }
}

export default userReducer;