import { useRef, useState } from "react";
import { propertyCreate } from "../../../actions/admin";
import { useDispatch, useSelector } from "react-redux";
import makeAnimated from 'react-select/animated';
import isNumeric from "validator/lib/isNumeric";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper';
import Select from "react-select";
import convertHEIC from "../../../utils/convertHEIC";
import CustomFadeLoader from "../../../components/Loader/CustomFadeLoader";
import CustomHashLoader from "../../../components/Loader/CustomHashLoader";

const CreateModal = ()=>{
    const categories = useSelector(state=>state.categories);
    const cities = useSelector(state=>state.cities);
    const [propertyName,setPropertyName] = useState();
    const [category,setCategory] = useState();
    const [description,setDescription] = useState();
    const [size,setSize] = useState();
    const [price,setPrice] = useState();
    const [city,setCity] = useState();
    const [area,setArea] = useState();
    const [unit,setUnit] = useState();
    const [ytb,setYtb] = useState();
    const [featureOptions,setFeatureOptions] = useState();
    const [featureOptionsSelected,setFeatureOptionsSelected] = useState();
    const [images,setImages] = useState();
    const [createStatus,setCreateStatus] = useState(false);
    const [imageLoading,setImageLoading] = useState(false);
    const admin = useSelector(state=>state.admin);
    const cancel = useRef();
    const dispatch = useDispatch();
    const page = 1;
    const animatedComponents = makeAnimated();
    const features = {
        FLAT : ["Children Play Area" ,"24 Hours Security" ,"24 Hours Water supply" ,"Elevator" ,"Gazebo" ,"CCTV Camera" ,"CLASS ENTRANCE","SCHOOLBUS DROP,OFF,ZONE","ENTRANCEFOYER","INDOOR GAMES","MULTIPURPOSE Hall","FIRE SAFETY SYSTEM"],
        BUNGLOW : ["24 * 7 Security" ,"24*7 Water Supply" ,"Car Parking Space" ,"Common Garden" ,"Senior Citizen Seating Area"],
        PLOT : ["Compound Wall","Gymnasium", "Library","Restaurant","Swimming Pool"],
    }
    const units = {
        ACRE : "ACRE",
        SQFT : "SQ. Feet",
        BHK : "BHK",
    }
    const inputHandler = (e)=>{
        if(e.target.name==="propertyName")
        {
            setPropertyName(e.target.value);
        }
        else if(e.target.name==="categoryName")
        {

            setCategory(e.target.value)
            if(features[categories.category.message.filter((value)=>value._id===e.target.value)[0].name])
            {
                setFeatureOptions(features[categories.category.message.filter((value)=>value._id===e.target.value)[0].name].map((val)=>{return {value:val,label:val.toUpperCase()}}));
            }
            else
            {
                setFeatureOptions(undefined)
            }
        }
        else if(e.target.name==="unit")
        {
            setUnit(e.target.value)
        }
        else if(e.target.name==="description")
        {
            setDescription(e.target.value)
        }
        else if(e.target.name==="size")
        {
            isNumeric(e.target.value) && e.target.value>0?setSize(e.target.value):setSize(size);
        }
        else if(e.target.name==="cityName")
        {
            setCity(e.target.value);
        }
        else if(e.target.name==="areaName")
        {
            setArea(e.target.value);
        }
        else if(e.target.name==="price")
        {
            isNumeric(e.target.value) && e.target.value>0?setPrice(e.target.value):setPrice(price);
        }
        else if(e.target.name==="ytb")
        {
            setYtb(e.target.value)
        }

    }

    const fileHandler = async (e)=>{
        setImageLoading(true);
        const urls = await convertHEIC(e);
        setImageLoading(false);
        setImages(urls)
    }
    const propertyFormHandler = (e)=>{
        e.preventDefault();
        setCreateStatus(true);
        dispatch(propertyCreate({propertyName,category,size,unit,area,city,price,images,featureOptionsSelected,description,ytb,page}));

    }
    if(createStatus && admin && !admin.loading && !admin.error)
    {
        cancel.current.click();
        setCreateStatus(false);
    }

    return <div className="modal fade" id="createModal" aria-hidden="true" tabIndex="-1" aria-labelledby="exampleModalLabel">
    <div className="modal-dialog modal-dialog-centered modal-xl">
      {
        <div className="modal-content">
        <div className="modal-header">
          <h2 className="modal-title" id="propertyModelLabel">Add Property</h2>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        {
            admin.loading?<CustomHashLoader />:
            <div className="modal-body">
            <div className="d-flex justify-content-around">
                <div className="col-4 d-flex flex-column justify-content-center align-items-center">
                    <Swiper navigation={true} modules={[Navigation]} className="col-12 mb-5">
                        {
                            images && Array.from(images).map((value,key)=>
                            <SwiperSlide className="d-flex align-items-center" key={key}>
                                <img src={URL.createObjectURL(value)} className="my-auto img-fluid img" alt={value.name} />
                            </SwiperSlide>
                        )
                        }
                    </Swiper>
                    {
                        imageLoading?<CustomFadeLoader /> :
                        <input type="file" name="images" className="form-control" id="" onChange={fileHandler} multiple required/>
                    }
                </div>
                <div className="col-7">
                    <div className="mb-3">
                        <label htmlFor="propertyName" className="form-label" onChange={inputHandler}>Property Name</label>
                        <input type="text" className="form-control" id="propertyName" name="propertyName" value={propertyName} onChange={inputHandler}/>
                    </div>
                    <div className="d-flex">
                        <div className="col-6">
                            <div className="pe-2">
                                <label htmlFor="categoryName" className="form-label">Category</label>
                                <select className="form-select" onChange={inputHandler} name="categoryName" value={category}>
                                {!category && <option defaultValue>Select Category</option>}
                                {
                                    !categories.loading && categories.category.message.map((value,j)=><option key={j} value={value._id}>{value.name}</option>)
                                }
                                </select>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="ps-2">
                                <div className="mb-3">
                                    <label htmlFor="price" className="form-label">Price</label>
                                    <input type="number" className="form-control" id="price" name="price" value={price} onChange={inputHandler}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-3 d-flex">
                        <div className="col-6">
                            <div className="pe-2">
                                <label htmlFor="cityName" className="form-label">City</label>
                                <select className="form-select" onChange={inputHandler} name="cityName" value={city}>
                                {!city && <option defaultValue>Select City</option>}
                                {
                                    !cities.loading && cities.cities.map((value,j)=><option key={j} value={value._id}>{value.name}</option>)
                                }
                                </select>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="ps-2">
                                <div className="mb-3">
                                        <label htmlFor="areaName" className="form-label">Area</label>
                                        <select className="form-select" onChange={inputHandler} name="areaName" value={area}>
                                            {!city && <option defaultValue>Select City First</option>}
                                            {city && !area && <option defaultValue>Select Area</option>}
                                            {
                                                city && cities.cities.filter((value)=>value._id===city)[0].areas.map((value,j)=><option key={j} value={value}>{value}</option>)
                                            }
                                        </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-3 d-flex">
                        <div className="col-6">
                            <div className="pe-2">
                                <label htmlFor="size" className="form-label">Size</label>
                                <input type="number" className="form-control" id="size" name="size" value={size} onChange={inputHandler}/>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="ps-2">
                                <label htmlFor="unit" className="form-label">Unit</label>
                                <select className="form-select" onChange={inputHandler} name="unit" value={unit}>
                                    {!unit && <option defaultValue>Select Unit</option>}
                                    {
                                        (Object.keys(units)).map((value,key)=><option value={value} key={key}>{units[value]}</option>)
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="mb-3 d-flex">
                        <div className="col-12">
                            {
                            category!=="" && featureOptions?<Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                defaultValue={featureOptionsSelected}
                                onChange={(e)=>{setFeatureOptionsSelected(e.map((val)=> val.value))}}
                                isMulti
                                id="featureSelect"
                                options={featureOptions}
                            />:""
                            }
                        </div>
                    </div>
                    <div className="mb-3 d-flex">
                        <div className="col-12">
                            <div className="pe-2">
                                <label htmlFor="ytb" className="form-label">Youtube Link</label>
                                <input type="text" className="form-control" id="ytb" name="ytb" value={ytb} onChange={inputHandler}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="mb-3">
                    <label htmlFor="description" className="form-label">Description</label>
                    <textarea className="form-control" placeholder="Write Your Description Here" id="description" name="description" onChange={inputHandler} value={description} rows="5"></textarea>
                </div>
            </div>
            </div>
        }
        <div className="modal-footer">
          <button type="button" className="btn btn-danger" data-bs-dismiss="modal" ref={cancel}>Cancel</button>
          <button type="button" onClick={propertyFormHandler} className="btn btn-success">Save</button>
        </div>
        </div>
      }
    </div>
  </div>
}

export default CreateModal