import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInquiries } from "../../../actions/admin";
import { Link } from "react-router-dom";
import {BsWhatsapp} from "react-icons/bs"
import {MdEmail} from "react-icons/md"
import "./completedInquiries.css"
import CustomFadeLoader from "../../../components/Loader/CustomFadeLoader";

const CompletedInquiries = ()=>{
    const dispatch = useDispatch();
    const inquiries = useSelector(state=>state.inquiries);
    useEffect(()=>{
        dispatch(getInquiries())
    },[dispatch])
    return <>
        <div className="table-outer inquiry-table my-2">
        {
            inquiries.loading?
            <div className="d-flex justify-content-center align-items-center" style={{height:"90vh",width:"75vw"}}>
                <CustomFadeLoader />
            </div>:
            <table className="table table-dark bordered fs-5 table-striped table-hover">
                <thead className="table-light">
                    <th className="text-nowrap text ps-2">First Name</th>
                    <th className="text-nowrap text ps-2">Last Name</th>
                    <th className="text-nowrap text ps-2">Email</th>
                    <th className="text-nowrap text ps-2">Mobile</th>
                    <th className="text-nowrap text ps-2">Property</th>
                </thead>
                <tbody>
                {
                        inquiries.inquiries && inquiries.inquiries.status && inquiries.inquiries.message.filter((value)=>value.completed===true).map((value)=><tr>
                                                        <td className="text-nowrap">{value.fName}</td>
                                                        <td className="text-nowrap">{value.lName}</td>
                                                        <td className="text-nowrap"><span className="d-flex align-items-center gap-2">{value.email} <a href={`mailto:${value.email}`} target="_"><MdEmail className="text-primary fs-4"/></a></span></td>
                                                        <td className="text-nowrap"><span className="d-flex align-items-center gap-2">{value.mobile} <a href={`http://wa.me/+91${value.mobile}`} target="_"><BsWhatsapp className="text-success fs-4"/></a></span></td>
                                                        <td className="text-nowrap"><Link to={`/PropertyDetails/${value.property}`}>{value.property}</Link></td>
                                                    </tr>)
                    
                }
                </tbody>
            </table>
        }
        </div>
    </>;
}

export default CompletedInquiries