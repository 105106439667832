import axios from "axios"
import { ALL_CITY_FAIL, ALL_CITY_REQUEST, ALL_CITY_SUCCESS } from "../constants/cityConstants"
import endpoint from "../utils/endpoint"

export const getCities = ()=>{
    return async (dispatch)=>{
        try
        {
            dispatch(
                {
                 type : ALL_CITY_REQUEST
                })
            const cities = await axios.get(endpoint+"/api/satyam/city");
            dispatch({
                type : ALL_CITY_SUCCESS,
                payload : cities.data.message
            })
        }
        catch(e)
        {
            dispatch({
                type : ALL_CITY_FAIL,
                message : e.response.data.message
            })
        }
    }
}