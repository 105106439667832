import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";
// import isNumeric from 'validator/lib/isNumeric';
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import makeAnimated from 'react-select/animated';
import { getAllProperties } from "../../../actions/getProperties";
import {FiEdit} from "react-icons/fi"
import {MdDeleteForever} from "react-icons/md"
import "./dashboard.css"
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import EditModal from "./EditModal";
import CreateModal from "./CreateModal";
// import EditModal from "./EditModal";
import endpoint from "../../../utils/endpoint"
import img_endpoint from "../../../utils/img_endpoint";
import CustomFadeLoader from "../../../components/Loader/CustomFadeLoader";

const Dashboard = (props)=>{
    const categories = useSelector(state=>state.categories);
    const cities = useSelector(state=>state.cities);
    const [propertyName,setPropertyName] = useState("");
    const [category,setCategory] = useState("");
    const [description,setDescription] = useState("");
    const [size,setSize] = useState("");
    const [price,setPrice] = useState("");
    const [city,setCity] = useState("");
    const [area,setArea] = useState("");
    const [ytb,setYtb] = useState("");
    const [featureOptions,setFeatureOptions] = useState(undefined);
    const [featureOptionsSelected,setFeatureOptionsSelected] = useState(undefined);
    const [images,setImages] = useState(undefined);
    const [sizeUnit,setSizeUnit]= useState("");
    const [id,setId] = useState("");
    const dispatch = useDispatch();
    const properties = useSelector(state=>state.properties);
    const features = {
        FLAT : ["Children Play Area" ,"24 Hours Security" ,"24 Hours Water supply" ,"Elevator" ,"Gazebo" ,"CCTV Camera" ,"CLASS ENTRANCE","SCHOOLBUS DROP,OFF,ZONE","ENTRANCEFOYER","INDOOR GAMES","MULTIPURPOSE Hall","FIRE SAFETY SYSTEM"],
        BUNGLOW : ["24 * 7 Security" ,"24*7 Water Supply" ,"Car Parking Space" ,"Common Garden" ,"Senior Citizen Seating Area"],
        PLOT : ["Compound Wall","Gymnasium", "Library","Restaurant","Swimming Pool"],
    }
  
    const filterIds = (id,arr)=>{
      for(let i in arr)
      {
        if(arr[i]._id===id)
        {
          return arr[i].name
        }
      }
    }
    const page = props.page;
    useEffect(()=>{
        dispatch(getAllProperties({page}))
    },[dispatch,page])
    const loadData = (value)=>{
        setId(value._id)
        setPropertyName(value.name);
        setCategory(filterIds(value.category,categories.category.message));
        setDescription(value.description);
        setSize(value.size);
        setPrice(value.price);
        setCity(value.city);
        setArea(value.area);
        setSizeUnit(value.sizeUnit)
        setYtb(value.youtube);
        setImages(value.images)
        if(features[categories.category.message.filter((val)=>val._id===value.category)[0].name])
        {
            setFeatureOptions(features[categories.category.message.filter((val)=>val._id===value.category)[0].name].map((val)=>{return {value:val,label:val.toUpperCase()}}));
        }
        else
        {
            setFeatureOptions(undefined)
        }
        setFeatureOptionsSelected(value.facilities);
        setImages(value.images);
    }
    
    const propertyDeleteHandler = async (id)=>{
            try
            {
                const propertyDeleted = await axios.delete(`${endpoint}/api/satyam/property/${id}`);
                toast.success(`Property : ${propertyDeleted.data.message.name} Deleted Successfully`, {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
                dispatch(getAllProperties({page}))
            }
            catch(e)
            {
                toast.error(`Failed To Delete Property : ${e.response.data.message}`, {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
    }
    return <>
        <CreateModal />
        <EditModal id={id} propertyName={propertyName} category={category} description={description} size={size} price={price} city={city} area={area} unit={sizeUnit} ytb={ytb} featureOptions={featureOptions} featureOptionsSelected={featureOptionsSelected} images={images} page={page} />
        <div className="table-responsive col-12 m-0 p-0">
              {
                properties.loading?
                <div className="d-flex justify-content-center align-items-center" style={{height:"80vh",width:"75vw"}}>
                  <CustomFadeLoader />
                </div>:
                <table className="table table-dark">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Image</th>
                      <th scope="col">Name</th>
                      <th scope="col">Category</th>
                      <th scope="col">Size</th>
                      <th scope="col">City</th>
                      <th scope="col">Area</th>
                      <th scope="col">Price</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                        properties.property.properties.map((value,i)=>
                        <tr key={i} className="align-middle fs-5">
                            <td>{(page-1)*6+(i+1)}</td>
                            <td className="p-1 text-center">
                                <img src={`${img_endpoint}${value.images[0]}`} alt={value.name} className="img-fluid p-0" style={{maxWidth:"150px",maxHeight:"9vh"}}/>
                            </td>
                            <td><Link className="nav-link text-success" to={`/PropertyDetails/${value._id}`}>{value.name}</Link></td>
                            <td>{filterIds(value.category,categories.category.message)}</td>
                            <td>{value.size} {value.sizeUnit}</td>
                            <td>{filterIds(value.city,cities.cities)}</td>
                            <td>{value.area}</td>
                            <td>{value.price}</td>
                            <td>
                                <div className="d-flex">
                                    <span className="actionIcons" onClick={()=>{loadData(value)}} data-bs-toggle="modal" data-bs-target="#editModal">
                                        <FiEdit className="fs-2 text-primary actionIcons" /> 
                                    </span>
                                    <span onClick={()=>{propertyDeleteHandler(value._id)}} className="actionIcons">
                                        <MdDeleteForever className="fs-2 text-danger" />
                                    </span>
                                </div>
                            </td>
                        </tr>
                    )
                    }
                  </tbody>
                </table>
              }
            </div>
            <div className="d-flex justify-content-end gap-4 col-12">
              <button type="button" className="btn submitBtn mx-4" data-bs-toggle="modal" data-bs-target="#createModal" >
                      Add Property
              </button>
            </div>
            <nav className="d-flex justify-content-center">
                  <ul className="pagination">
                    <li className="page-item">
                        <Link to={`/admin/dashboard/${page-1>0?page-1:1}`} className="page-link" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                      </Link>
                    </li>
                    {
                       Math.ceil(properties.property.numberOfProperties/6)>0?(new Array(Math.ceil(properties.property.numberOfProperties/6)).fill(1)).map((key,value)=> <li key={value} className="page-item">
                            <Link to={`/admin/dashboard/${value+1}`} key={value} className={`page-link ${value+1===Number(page)?"active":""}`}>
                            <span aria-hidden="true">{value+1}</span>
                        </Link>
                        </li>):""
                    }
                    <li className="page-item">
                      <Link to={`/admin/dashboard/${page+1<Math.ceil(Number(properties.property.numberOfProperties)/6)?page+1:Math.ceil(Number(properties.property.numberOfProperties)/6)}`} className="page-link" href="#" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                      </Link>
                    </li>
                  </ul>
                </nav>
    </>;
}

export default Dashboard;