import { Link, useNavigate } from "react-router-dom";
import "./header.css"
import { useDispatch,useSelector } from "react-redux";
import { useState } from "react";
import {FaUserAlt} from "react-icons/fa"
import logout from "../../actions/logout";
import CustomFadeLoader from "../Loader/CustomFadeLoader";
// import getUser from "../../actions/getUser";

const Header = () =>{
    const dispatch = useDispatch();
    const categories = useSelector(state=>state.categories)
    const user = useSelector(state=>state.user); 
    const navigate = useNavigate();
    const [isProfileActive,setIsProfileActive] = useState(false);
    const logoutHandler = ()=>{
        dispatch(logout());
    }
    return <>
            <header className="justify-content-around header position-relative py-2" style={{"zIndex" : 1}}>
                <div className="navbar navbarBg navbar-expand-md align-items-center navbar-dark justify-content-around flex-wrap position-absolute top-0 w-100">
                    <div className="col-md-2 col-sm-2">
                        <div className="container my-2 text-center">
                            <Link to="/" className="navbar-brand fw-bold fs-1 text-light">
                                <img src="../../images/logo.PNG" alt="Satyam Property Consultancy Logo" className="logo" />
                            </Link>
                        </div>
                    </div>
                    <div className="col-sm-4 d-md-none d-sm-block col-0"></div>
                    <button className="navbar-toggler col-md-0 col-2 col-sm-1" type="button" data-bs-toggle="collapse" data-bs-target="#navbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="col-md-10 col-12 container-fluid navbarBg mt-2 pe-5">
                        <div className="collapse navbar-collapse my-2 col-12 justify-content-end" id="navbar">
                            <ul className="navbar-nav align-items-end">
                                <li className="nav-item">
                                    <Link to="/" className="nav-link text-nowrap active">Home</Link>
                                </li>
                                <li className="nav-item dropdown-link">
                                    <Link to="#" className="nav-link text-nowrap dropdown-toggle text-end">Catagory</Link>
                                    <ul className="dropdown-menu dropdown-menu-dark">
                                        {
                                            categories.loading?
                                            <CustomFadeLoader />:
                                            categories.category.message.map((category,i)=>
                                                <li key={i}>
                                                    <a href={`/property/search?category=${category._id}`} className='dropdown-item fw-light'>{category.name}</a>
                                                </li>
                                            )
                                        }
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <Link to="/property" className="nav-link text-nowrap">Properties</Link>
                                </li>
                                <li className="nav-item">
                                    {
                                        user.status?
                                        <>
                                            <div className="d-flex justify-content-center align-items-center">
                                                <h4 className="mx-2 my-0 userName">{user.user.fname}</h4>
                                                <div className="position-relative dropdown-toggle text-light">
                                                    <button className="btn rounded-circle p-0 userIcon"><FaUserAlt className="bg-light rounded-circle fs-1 p-1 text-primary" onClick={()=>{setIsProfileActive(!isProfileActive)}}/></button>
                                                    <ul className={`list-group ${isProfileActive?"":"d-none"} position-absolute profileMenu`} id="profileMenu">
                                                        <Link className="list-group-item list-group-item-action list-group-item-info fs-5" to="/admin/profile">Profile</Link>
                                                        <button className="list-group-item list-group-item-action list-group-item-info fs-5" onClick={logoutHandler} >Log Out</button>
                                                    </ul>
                                                </div>
                                            </div>
                                        </>:
                                        <button className="btn btn-md bg-dark text-light" onClick={()=>{
                                            navigate("/login");
                                        }}>Log in</button>
                                    }
                                    
                                    
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
    </>
}

export default Header;
