import { useDispatch, useSelector } from "react-redux";
import "./AdminPanel.css"
import { FaUserAlt } from 'react-icons/fa';
import logout from "../../actions/logout";
import { useState } from "react";
import UserProfile from "./UserProfile/userProfile";
import { Link, Navigate, useParams } from "react-router-dom";
import Category from "./Category/Category";
import { ToastContainer } from "react-toastify";
import Cities from "./Cities/Cities";
import Inquiries from "./Inquiries/Inquiries";
import Dashboard from "./Dashboard/Dashboard";
import Features from "./Features/Features";
import Area from "./Area/Area";
import CompletedInquiries from "./CompletedInquiries/CompletedInquiries";
import CustomFadeLoader from "../../components/Loader/CustomFadeLoader";
// import { UserProfile } from '/UserProfile/UserProfile.jsx';

const PROFILE = "profile";
const DASHBOARD = "dashboard";
const CATEGORIES = "categories";
const FEATURES = "features";
const CITIES = "cities";
const INQUIRIES = "inquiries";
const COMPLETED_INQUIRIES = "completed_inquiries";
const AREAS = "areas"
const navigatorStates = [PROFILE,DASHBOARD,CATEGORIES,FEATURES,CITIES,INQUIRIES,AREAS,COMPLETED_INQUIRIES]
const AdminPanel = ()=>{
    const [isProfileActive,setIsProfileActive] = useState(false);
    const user =  useSelector(state=>state.user);
    const dispatch = useDispatch();
    const navigatorState = useParams().section;
    let page = useParams().page;
    page = page?page:1;
    
    if(!navigatorStates.includes(navigatorState))
    {
        return <Navigate to="/admin/dashboard" />
    }
    const logoutHandler = ()=>{
        dispatch(logout());
    }

    return <>
        <div className="d-flex ">
            <div className="d-flex col-6 col-md-2 px-2 bg-dark flex-column justify-content-center align-items-center adminNav">
                <div className="my-4">
                    <Link to="/">
                        <img src="../../images/logo.PNG" alt="Satyam Property Consultancy Logo" className="logo" />
                    </Link>
                </div>
                <hr className="w-100 border-light mb-4" />
                <ul className="nav m-0 nav-pills flex-column mb-auto">
                    <li className="nav-item">
                        <Link className={`nav-link ${navigatorState===PROFILE?"active":""}`} to={`/admin/${PROFILE}`} aria-current="page">
                            Profile
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className={`nav-link ${navigatorState===DASHBOARD?"active":""}`} to={`/admin/${DASHBOARD}`} >
                            Dashboard
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className={`nav-link ${navigatorState===CATEGORIES?"active":""}`} to={`/admin/${CATEGORIES}`} >
                            Categories
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className={`nav-link ${navigatorState===FEATURES?"active":""}`} to={`/admin/${FEATURES}`} >
                            Features
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className={`nav-link ${navigatorState===CITIES?"active":""}`} to={`/admin/${CITIES}`} >
                            Cities
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className={`nav-link ${navigatorState===AREAS?"active":""}`} to={`/admin/${AREAS}`} >
                            Areas
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className={`nav-link ${navigatorState===INQUIRIES?"active":""}`} to={`/admin/${INQUIRIES}`} >
                            Pending Inquiries
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className={`nav-link ${navigatorState===COMPLETED_INQUIRIES?"active":""}`} to={`/admin/${COMPLETED_INQUIRIES}`} >
                            Settled Inquiries
                        </Link>
                    </li>
                </ul>
                {
                    user.loading?<CustomFadeLoader />:
                    <div className="mx-4 px-4 w-100 my-2 dropup">
                        <div className="d-flex justify-content-around w-100 align-items-center">
                            <h4 className="mx-2 display-6 my-0 userName">{user.user.fname}</h4>
                            <div className="position-relative dropdown-toggle text-light">
                                <button className="btn rounded-circle p-0 userIcon"><FaUserAlt className="bg-light rounded-circle p-1 text-primary" style={{"fontSize":"3em"}} onClick={()=>{setIsProfileActive(!isProfileActive)}} /></button>
                                <ul className={`list-group ${isProfileActive?"":"d-none"} position-absolute profileMenuAdmin`} id="profileMenu">
                                    <button className="list-group-item list-group-item-action list-group-item-info text-center"  onClick={logoutHandler} >Log Out</button>
                                </ul>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className="col-6 col-md-10 mx-auto">
                {
                    navigatorState===PROFILE?<UserProfile />:""
                }
                {
                    navigatorState===CATEGORIES?<Category />:""
                }
                {
                    navigatorState===FEATURES?<Features />:""
                }
                {
                    navigatorState===CITIES?<Cities />:""
                }
                {
                    navigatorState===INQUIRIES?<Inquiries />:""
                }
                {
                    navigatorState===DASHBOARD?<Dashboard page={page} />:""
                }
                {
                    navigatorState===AREAS?<Area />:""
                }
                {
                    navigatorState===COMPLETED_INQUIRIES?<CompletedInquiries />:""
                }
                <ToastContainer className="w-auto"/>
            </div>
        </div>
    </>;

}

export default AdminPanel;