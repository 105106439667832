import { useState } from "react";
import "./Login.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from "react-redux";
import login from "../../actions/login";
import { Navigate, useNavigate } from "react-router-dom";
import CustomFadeLoader from "../../components/Loader/CustomFadeLoader";

const Login = ({prev})=>{
    const [email,setEmail] = useState("");
    const [password,setPassword] = useState("");
    const dispatch = useDispatch();
    const user = useSelector(state=>state.user);
    const navigator = useNavigate();

    if(user.status)
    {
        return <Navigate to="/"/>
    }
    const inputHandler = (e)=>{
        if(e.target.name==="email")
        {
            setEmail(e.target.value);
        }
        else
        {
            setPassword(e.target.value);
        }
    }
    const loginController = async ()=>{
        dispatch(login(email,password,toast,navigator));
    }
    return <>
        <div className="bg-img-login d-flex justify-content-center align-items-center">
            {
            user.loading?
            <CustomFadeLoader />:
            <div className="loginBox rounded-2">
                <h4 className="loginHeader text-light">
                    Login
                </h4>
                <hr/>
                <div className="form-floating m-4">
                    <input type="email" name="email" id="email" className="form-control" placeholder="Your Email" value={email} onChange={inputHandler}/>
                    <label htmlFor="email">Email</label>
                </div>
                <div className="form-floating m-4">
                    <input type="password" name="password" id="password" className="form-control" placeholder="Your Password" value={password} onChange={inputHandler} />
                    <label htmlFor="password">Password</label>
                </div>
                <button type="button" className="submit-btn submit-btn text-light fw-bold form-control w-75 mx-auto my-4" onClick={loginController}>Submit</button>
            </div>
            }
            <ToastContainer className="w-auto"/>
        </div>
    </>;
}

export default Login;