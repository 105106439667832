import { useEffect, useState } from "react";
import "./category.css"
import { useDispatch, useSelector } from 'react-redux';
import {IoMdAdd} from 'react-icons/io'
import { removeCategory, saveCategory } from "../../../actions/admin";
import { useNavigate } from "react-router-dom";


const Category = ()=>{
    const categories = useSelector(state=>state.categories.category.message)
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [categoryDisplay,setCategoryDisplay] = useState(categories);
    const [categoryName,setCategoryName] = useState("");
    const [categorySearch,setCategorySearch] = useState("");
    const [dropdown,setDropdown] = useState(false);
    const categorySearchHandler = (e)=>{
        setCategorySearch(e.target.value);
        const regex = new RegExp(e.target.value,"gi");
        const temp = categories.filter((value)=>{
            return value.name.match(regex)===null?false:true
        })
        setCategoryDisplay([...temp]);
    }
    const categoryNameHandler = (e)=>{
        setCategoryName(e.target.value);
    }
    const save = (e)=>{
        dispatch(saveCategory(categoryName,navigate))
    }
    const remove = (e)=>{
        dispatch(removeCategory(e.target.name,navigate))
    }

    useEffect(()=>{
        setCategoryDisplay(categories)
    },[categories])

    return <>
        <div className="d-flex category justify-content-center align-items-center">
            <div className="card categoryCard">
              <div className="card-body">
                <h1 className="card-title text-center">Categories</h1>
                <div className="d-flex justify-content-center align-items-center dropdown">
                    <input type="text" name="categorySearch" className="form-control my-1 me-4" placeholder="Search Here" id="categorySearch" value={categorySearch} onChange={categorySearchHandler} />
                    <button className="btn btn-success rounded-circle addBtn" onClick={()=>{setDropdown(!dropdown)}}> <IoMdAdd className="fs-2" /> </button>
                </div>
                <div className={`input-group my-4 ${dropdown?"":"d-none"}`}>
                    <input type="text" name="category" className="form-control" placeholder="Enter Category Name" id="categoryName" value={categoryName} onChange={categoryNameHandler} />
                    {categoryName && <button className="btn btn-success" onClick={save}>Add</button>}
                </div>
                <ul className="list-group list-group-flush">
                    {
                        categoryDisplay?categoryDisplay.map((value)=><li className="list-group-item list-group-item-action">{value.name} <button className="btn btn-danger" onClick={remove} name={value.name}>Remove</button></li>):""
                    }
                </ul>
              </div>
            </div>
        </div>
    </>;
}

export default Category;