import { INQUIRY_REQUEST,INQUIRY_SUCCESS,INQUIRY_FAIL } from "../constants/adminConstants"

const initialState = {
    loading : false
}

const inquiryReducer = (status=initialState,action)=>{
    switch(action.type)
    {
        case INQUIRY_REQUEST:{
            return {
                ...status,
                loading:true
            }
        }
        case INQUIRY_SUCCESS:{
            return {
                ...status,
                loading:false,
                inquiries: action.payload
            }
        }
        case INQUIRY_FAIL:{
            return {
                ...status,
                loading:false,
            }
        }
        default :
        {
            return {
                ...status
            }
        }
    }
}

export default inquiryReducer;