import FilterSection from "../FilterSection/FilterSection";
import { VscSettings } from "react-icons/vsc";
import "./SearchSection.css"
import { useState } from "react";

const SearchSection = ({search,setSearch,category,setCategory,city,setCity,cities,setCurrentMin,setCurrentMax,filters})=>{

    const [filterShow,setFilterShow] = useState(false);
    const toogleFilters = ()=>{
        setFilterShow(!filterShow);
    }

    return <div className="my-4">
            <div className="col-md-7 input-group col-10 mx-auto text-center d-flex m-0 flex-wrap justify-content-center gap-2 align-items-center position-relative">
                <div className="col-8">
                    <input 
                        type="text" 
                        name="search" 
                        id="search" 
                        placeholder="Search Here" 
                        className="form-control search" 
                        value={search} 
                        onChange={(e)=>{setSearch(e.target.value)}}
                    />
                </div>
                {
                    filters && 
                    <div className="col-2 d-md-none d-sm-block d-block">
                        <button type="button" className="btn btn-light" onClick={toogleFilters}>
                            <VscSettings size={25}/>
                        </button>
                        <div className={`tiny-filters ${filterShow?"":"d-none"}`}>
                            <FilterSection category={category} setCategory={setCategory} city={city} setCity={setCity} cities={cities} maxPrice={100000000} minPrice={0} setCurrentMax={setCurrentMax} setCurrentMin={setCurrentMin} />
                        </div>
                    </div>
                }
            </div>
        </div>
}

export default SearchSection;