import { INQUIRY_FORM_SUBMIT_FAIL, INQUIRY_FORM_SUBMIT_REQUEST, INQUIRY_FORM_SUBMIT_SUCCESS } from "../constants/inquiryFormConstants";

const initialState = {
    loading : false
}

const inquiryFormReducer = (status=initialState,action)=>{
    switch(action.type)
    {
        case INQUIRY_FORM_SUBMIT_REQUEST:{
            return {
                ...status,
                loading:true
            }
        }
        case INQUIRY_FORM_SUBMIT_SUCCESS:{
            return {
                ...status,
                loading:false
            }
        }
        case INQUIRY_FORM_SUBMIT_FAIL:{
            return {
                ...status,
                loading:false,
            }
        }
        default :
        {
            return {
                ...status
            }
        }
    }
}

export default inquiryFormReducer