import { ALL_CATEGORY_FAIL, ALL_CATEGORY_REQUEST, ALL_CATEGORY_SUCCESS } from "../constants/categoryConstants"

const initialState = {category : [],loading:true}

const categoryReducer = (state = initialState,action)=>{
    switch(action.type)
    {
        case ALL_CATEGORY_REQUEST:
        {
            return {
                ...state,
            }
        }
        case ALL_CATEGORY_SUCCESS:
        {
            return {
                ...state,
                category:action.payload,
                loading:false
            }
        }
        case ALL_CATEGORY_FAIL:
        {
            return {
                ...state,
                loading:false
            }
        }
        default:
        {
            return {
                ...state
            }
        }
    }
}

export default categoryReducer;