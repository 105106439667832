import { ALL_CATEGORY_FAIL, ALL_CATEGORY_REQUEST, ALL_CATEGORY_SUCCESS } from "../constants/categoryConstants"
import axios from "axios"
import endpoint from "../utils/endpoint"

export const getCategories = ()=>{
    return async (dispatch)=>{
        try {
            dispatch({
                type:ALL_CATEGORY_REQUEST
            })
            const categories = await axios.get(endpoint+"/api/satyam/category");
            dispatch({
                type:ALL_CATEGORY_SUCCESS,
                payload:categories.data
            })
        }
        catch(e)
        {
            dispatch({
                type:ALL_CATEGORY_FAIL,
                payload:e.response.data.message
            })
        }
    }
}