import { useSelector } from "react-redux";
import "./MidSection.css"
import { useState } from "react";
import CustomFadeLoader from "../Loader/CustomFadeLoader";

const MidSection = ()=>{
    const categories = useSelector(state=>state.categories)
    const cities = useSelector(state=>state.cities)
    const [citySelected,setCitySelected] = useState(undefined);
    const citySelectHandler = (e)=>{
        setCitySelected(e.target.value)
    }
    return <>
        <section className="container text-center content d-flex align-items-center justify-content-center">
            <div className="container-fluid">
                <h1 className="text-center text-light header-content">Find Your Property Today!</h1>
                <p className="header-content-small mt-2">
                    Choose Your Location
                </p>
                <form action="/property/search" className="row col-md-10 col-sm-12 mx-auto">
                    <div className="col-md-3 col-sm-12 my-1 mx-0 px-1">
                        <select name="city" id="city" className="form-select py-3" placeholder="Select City" onChange={citySelectHandler}>
                            <option defaultValue>City</option>
                            {
                                cities.loading?
                                <CustomFadeLoader />:
                                cities.cities.map((city,i)=>
                                <option value={`${city._id}`} key={i}>{city.name}</option>
                                )
                            }        
                                
                        </select>
                    </div>
                    <div className="col-md-3 col-sm-12 my-1 mx-0 px-1">
                        
                        <select className="form-select py-3" disabled={citySelected?false:true}>
                            {!citySelected?<option defaultValue>Please Select City First</option>:""}
                            {cities.cities.filter((value)=>value._id===citySelected)[0]?cities.cities.filter((value)=>value._id===citySelected)[0].areas.map((value,key)=><option value={`${value}`} key={key}>{value}</option>):""}
                        </select>
                    </div>
                    <div className="col-md-3 col-sm-12 my-1 mx-0 px-1">
                        <select name="category" id="category" className="form-select py-3" placeholder="Select Property" >
                                <option>Category</option>
                                {
                                    categories.loading?
                                    <CustomFadeLoader />:
                                    categories.category.message.map((category,i)=>
                                    <option value={`${category._id}`} key={i}>{category.name}</option>
                                    )
                                }
                        </select>
                    </div>
                    <div className="col-md-3 col-sm-12 my-1 mx-0 px-1">
                        <input type="submit" className="form-control submit-btn text-light fw-bold"/>
                    </div>
                </form>
            </div>        
        </section>
    </>
}

export default MidSection;