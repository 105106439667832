const { FadeLoader } = require("react-spinners")

const CustomFadeLoader = ()=>{
    return <FadeLoader
        color="#36d7b7"
        cssOverride={{
        margin: 'auto',
        background:'none'
        }}
    />
}

export default CustomFadeLoader;