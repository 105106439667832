import { ALL_PROPERTY_FAIL, ALL_PROPERTY_REQUEST, ALL_PROPERTY_SUCCESS, PROPERTY_DETAILS_FAIL, PROPERTY_DETAILS_REQUEST, PROPERTY_DETAILS_SUCCESS } from "../constants/propertyConstants"
import axios from "axios"
import endpoint from "../utils/endpoint"

export const getAllProperties = ({search,category,city,price=[0,100000000],page})=>{
    return async (dispatch) =>{
        try
        {
            dispatch({
                type:ALL_PROPERTY_REQUEST,
            })
            const data = {
                search,
                category,
                city,
                page,
                minPrice:price[0],
                maxPrice:price[1]
            }
            const properties = await axios.get(`${endpoint}/api/satyam/property`,{params:data});
            dispatch({
                type:ALL_PROPERTY_SUCCESS,
                payload:properties.data.message,
                loading:false,
                price
            })
        }
        catch(e)
        {
            dispatch({
                type:ALL_PROPERTY_FAIL,
                payload: e.response.data.message
            })
        }
        
    }
   
}

export const getPropertyDetails = (id)=>{
    return async(dispatch)=>
    {
        try
        {
            dispatch({
                type : PROPERTY_DETAILS_REQUEST,
                loading:true
            })
            const propertyDetails = await axios.get(`${endpoint}/api/satyam/property/${id}`);
            dispatch({
                type : PROPERTY_DETAILS_SUCCESS,
                payload : propertyDetails.data.message,
                loading:false
            })
        }
        catch(e)
        {
            dispatch({
                type : PROPERTY_DETAILS_FAIL,
                payload : e.response.data.message,
                loading:false
            })
        }
    }
}