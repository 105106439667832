import { useState } from "react";
import "./area.css"
import { useDispatch, useSelector } from 'react-redux';
import {IoMdAdd} from 'react-icons/io'

import { useNavigate } from "react-router-dom";
import { removeArea, saveArea } from "../../../actions/admin";


const Area = ()=>{
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const cities = useSelector(state=>state.cities.cities)
    const [areaName,setAreaName] = useState("");
    const [dropdown,setDropdown] = useState(false);
    const [citySelected,setCitySelected] = useState(null);
    const citySelectHandler = (e)=>{
        setCitySelected(e.target.value)
    }
    const areaNameHandler = (e)=>{
        setAreaName(e.target.value);
    }
    const save = ()=>{
        dispatch(saveArea(areaName,citySelected,navigate))
    }
    const remove = (area)=>{
        dispatch(removeArea(area,citySelected,navigate))
    }
    return <>
        <div className="d-flex area justify-content-center align-items-center">
        <div className="card areaCard">
          <div className="card-body">
            <h1 className="card-title text-center">Areas</h1>
            <div className="d-flex justify-content-center align-items-center dropdown">
                  <select className="form-select form-control me-2" onChange={citySelectHandler}>
                    {!citySelected?<option defaultValue>Select City</option>:""}
                    {
                        cities.map((city,key)=><option key={key} value={city._id}>{city.name}</option>)
                    }
                  </select>
                  {
                    citySelected && <button className="btn btn-success rounded-circle addBtn" onClick={()=>{setDropdown(!dropdown)}}> <IoMdAdd className="fs-2" /> </button>
                  }
            </div>
            <div className={`input-group my-4 ${dropdown?"":"d-none"}`}>
                <input type="text" name="area" className="form-control" placeholder="Enter Area Name" id="areaName" value={areaName} onChange={areaNameHandler} />
                {areaName && <button className="btn btn-success" onClick={()=>{save()}}>Add</button>}
            </div>
            <ul className="list-group list-group-flush">
                {
                    cities.filter((value)=>value._id===citySelected)[0]?cities.filter((value)=>value._id===citySelected)[0].areas.map((value,key)=><li key={key} className="list-group-item list-group-item-action">{value} <button className="btn btn-danger" onClick={()=>{remove(value)}}>Remove</button></li>):""
                }
            </ul>
          </div>
        </div>
    </div>
    </>;
}

export default Area;