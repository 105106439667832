// import { Range, getTrackBackground } from "react-range";
import "./FilterSection.css"
// import axios from "axios";
import { useSelector } from "react-redux";
import InputRange from "../Inputs/InputRange/InputRange";
import CustomFadeLoader from "../Loader/CustomFadeLoader";

const FilterSection = ({category,setCategory,setCity,cities,minPrice,maxPrice,setCurrentMin,setCurrentMax})=>{
    const categories = useSelector(state=>state.categories);
    const categoryHandler = (e)=>{
        if(e.target.checked)
        {
            let temp = [...category]
            temp.push(e.target.value)
            setCategory(temp)
        }
        else
        {
            let temp = [...category];
            temp.splice(temp.indexOf(e.target.value),1);
            setCategory(temp);
        }
    }
    const cityHandler = async (e)=>{
        await setCity(e.target.value);
    }
    return <>
        <div className="position-relative">
            <h2 className="filterHeader text-center my-2">Filters</h2>
            <h4 className="px-4 py-2">
                Category
            </h4>
            <div className="px-4">
                {
                    categories.loading?
                    <CustomFadeLoader />:
                    categories.category.message.map((category,i)=>
                        <div key={i} className="mb-1 form-check">
                            <input type="checkbox" className="form-check-input" id={category.name} name={category.name} value={category._id} onChange={categoryHandler}/>
                            <label className="form-check-label" htmlFor={category.name}>{category.name}</label>
                        </div>)
                    
                }
            </div>
            <h4 className="px-4 py-2">
                City
            </h4>
            <div className="mb-3 mx-4">
              {cities.loading?<CustomFadeLoader />:
              <select className="form-select mx-auto" aria-label="Default select example" onChange={cityHandler}>
                <option defaultValue={true} value={""}>Select City</option>
                {
                    cities.cities.map((value,i)=><option key={i} value={value._id}>{value.name}</option>)
                }
              </select>
            }
            </div>
            <h4 className="px-4 py-2">
                Price
            </h4>
            <div className="mb-5 d-flex justify-content-center mx-4">
              <InputRange min={minPrice} max={maxPrice} setCurrentMax={setCurrentMax} setCurrentMin={setCurrentMin} />
            </div>
        </div>
    </>
}

export default FilterSection