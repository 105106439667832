import Header from "../../components/Header/header";
import MidSection from "../../components/MidSection/MidSection";
import Categories from "../../components/Categories/Categories";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import PartnerSlider from "../../components/PartnerSlider/PartnerSlider";
import SpecializedService from "../../components/Services/specializedService";
import "./Home.css"
import { useSelector } from "react-redux";
import Footer from "../../components/Footer/Footer";
import PartnerSlider from "../../components/PartnerSlider/PartnerSlider"
import Loading from "../../components/Loading/Loading";

const Home = ()=>{
    const logoutLoading = useSelector(state=>state.user.logoutLoading);
    const loading = useSelector(state=>state.user.loading);
    return <>
            {
            (logoutLoading || loading)?<Loading />:<>
            <div className="bg-img">
                <Header />
                <MidSection />
            </div>
            <Categories />
            <section className="container-fluid p-0 about">
                <div className="d-flex m-0 p-0 justify-content-md-between flex-wrap pb-sm-5 pb-5 pb-md-0">
                    <div className="col-md-6 col-sm-12">
                        <img src="images/about-image.jpeg" alt="About-us" className="img-fluid mw-25" />
                    </div>
                    <div className="col-md-5 col-sm-12 mx-2 my-auto py-md-0 p-sm-5">
                        <h3 className="title text-md-start text-sm-center text-center">
                            About Us
                        </h3>
                        <p className="my-4 text-md-start text-sm-center text-center">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                        </p>
                        <div className="text-md-start text-sm-center text-center">
                            <Link to ="#" className="read-more my-0">
                                <div className="read-more-transition"></div>
                                READ MORE
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            <div>
                <SpecializedService img={"specialized-services.png"} header={"Specialized"} headerHeighlighted={"Consulting"} content={"At Satyam Property Consultancy we strive to provide our customers with the best possible living experience. We specialize in new residential properties and new commercial properties, and pride ourselves on our commitment to quality and customer satisfaction. Our team of experienced professionals is dedicated to helping you find the perfect home or property to suit your needs. We are committed to providing you with the highest level of service and support throughout the entire process. Thank you for considering Satyam Property Consultancy for all of your real estate needs."} />
                <SpecializedService img={"home-showing.jpeg"} header={"Home"} headerHeighlighted={"Showing"} content={"With years of experience, our staff has the capabilities and expertise to take your business to the next level. At Satyam Property Consultancy we combine our insights and skills to transform your processes and strategies, and in turn, your company. We’re proud to help shape and improve how our clients structure and manage their business."} direction={false} />
                <SpecializedService img={"financial-service.jpg"} header={"Finance"} headerHeighlighted={"Service"} content={"As a real estate agent, we provide financial services such as mortgage brokerage, helping customers secure loans for purchasing property, and providing information and advice on financial products and options available to our customers. Additionally, we assist with the financial aspects of purchasing a property, such as negotiating prices, reviewing and explaining purchase agreements, and helping to secure financing."} />
                <SpecializedService img={"legal-service.jpg"} header={"Legal"} headerHeighlighted={"Service"} content={"As a real estate consultant, we provide legal services to customers related to the buying and selling of property. This may include reviewing and explaining purchase agreements, deeds, and other legal documents related to the sale of a property.Assisting with the transfer of ownership, including ensuring that all necessary paperwork is properly completed and filed.- Advising customers on local zoning laws and regulations, as well as any restrictions or requirements that may affect the property.- Providing information and assistance in navigating the legal aspects of purchasing or selling property, such as contract negotiations and dispute resolution.- Helping customers understand their rights and obligations as buyers or sellers of property, and providing guidance on how to protect their interests.- It is important to remember that as a real estate consultant , we are not a lawyer and we should not provide legal advice. if there is any legal issue, we should advise our customer to seek the advice of a licensed attorney."} direction={false} />
                <SpecializedService img={"interior-service.jpg"} header={"Interior"} headerHeighlighted={"Service"} content={"As a real estate consultant, we provide interior design and staging services to customers to help them prepare their property for sale or to help buyers envision how a property could be used. Some of the services we provide include Staging: arranging furniture and decor in a property to make it more attractive to potential buyers.- Space Planning: advising on how to best use the space in a property to make it more functional and attractive.- Color Consultation: helping customers choose colors and finishes that will enhance the appeal of a property.- Lighting Design: advising on how to use lighting to create a warm and inviting atmosphere in a property.- Home organization and decluttering: helping sellers to organize and declutter their home before they put it on the market.- Home renovation and remodelling: giving advice on cost-effective updates or renovations to improve the value of the property.It is important to remember that as a real estate consultant, we are not an interior designer but we should provide professional interior designers."} />
            </div>
            <section className="container-fluid p-0">
                <div className="container heading my-3">
                    <h1 className="p-md-5 p-sm-2 p-1 text-center">Founders</h1>
                </div>
                <PartnerSlider />
            </section>
            <Footer />
        </>}
    </>
}

export default Home;