import { Link } from "react-router-dom";
import { SocialIcon } from 'react-social-icons';

const Footer = ()=>{
    return <>
        <div className="d-flex flex-wrap bg-dark py-4">
            <div className="col-12 col-md-4 d-flex flex-wrap">
                <div className="col-12 px-5 text-center">
                    <img src="../../images/logo.PNG" className="img-fluid w-md-25 w-50 my-2" alt="Satyam Logo" />
                    <p className="text-muted text-light my-2 fs-5">𝖧𝖾𝗅𝗉 𝖳𝗈 𝖡𝗎𝗒 - 𝖲𝖾𝗅𝗅 - 𝖱𝖾𝗇𝗍 𝖮𝗇𝗅𝗒 𝖱𝖾𝗌𝗂𝖽𝖾𝗇𝖼𝗂𝖺𝗅 𝖯𝗋𝗈𝗉𝖾𝗋𝗍𝗒</p>
                </div>
            </div>
            <div className="col-12 px-5 py-2 col-md-4 col-sm-5 mx-auto">
                <div className="specializedHeader title text-light fs-2"> Links</div>
                <ul className="list-group list-group-flush">
                    <Link className="list-group-item bg-dark text-light">
                        Home
                    </Link>
                    <Link className="list-group-item bg-dark text-light">
                        Properties
                    </Link>
                    <Link className="list-group-item bg-dark text-light">
                        Contact Us
                    </Link>
                    <Link className="list-group-item bg-dark text-light">
                        About Us
                    </Link>
                </ul>
            </div>
            <div className="col-12 px-5 py-2 col-md-4 col-sm-7 mx-auto">
                <div className="specializedHeader title text-light fs-2">Others</div>
                <p className="text-muted text-light my-2 fs-5">Follow us on Social Media</p>
                <div className="my-2 d-flex flex-wrap gap-3">
                        <SocialIcon url="https://youtube.com/@satyampropertyconsultancy" className="bg-light rounded-circle" />
                        <SocialIcon url="https://www.instagram.com/satyampropertyconsultancy" />
                        <SocialIcon url="https://www.facebook.com/people/satyampropertyconsultancy/100087187639038/" />
                </div>
            </div>
        </div>
    </>
}

export default Footer;
