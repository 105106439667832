import {createStore, combineReducers, applyMiddleware} from "redux"
import {composeWithDevTools} from "redux-devtools-extension"
import thunk from "redux-thunk"
import {propertyReducer, propertyDetailsReducer} from "./reducers/propertyReducer"
import categoryReducer from "./reducers/categoryReducer"
import cityReducer from "./reducers/cityReducer"
import loginReducer from "./reducers/loginReducer"
import userReducer from "./reducers/userReducer"
import adminReducer from "./reducers/adminReducer";
import inquiryFormReducer from "./reducers/inquiryFormReducer";
import inquiryReducer from "./reducers/inquiryReducer";


const rootReducer = combineReducers({
    properties:propertyReducer,
    propertyDetails:propertyDetailsReducer,
    categories:categoryReducer,
    cities: cityReducer,
    login : loginReducer,
    user : userReducer,
    admin: adminReducer,
    inquiry: inquiryFormReducer,
    inquiries: inquiryReducer
})

const middleware = [thunk];

const store = createStore(rootReducer,composeWithDevTools(applyMiddleware(...middleware)))


export default store;