const { ALL_CITY_REQUEST, ALL_CITY_SUCCESS, ALL_CITY_FAIL } = require("../constants/cityConstants")

const initialState = {
    cities : [],
    loading: true
}
const cityReducer = (state= initialState,action)=>{
    switch(action.type)
    {
        case ALL_CITY_REQUEST:
        {
            return {
                ...state
            }
        }
        case ALL_CITY_SUCCESS:
        {
            return {
                ...state,
                loading:false,
                cities:action.payload
            }
        }
        case ALL_CITY_FAIL:
        {
            return {
                ...state,
                loading:false,
                error:action.payload
            }
        }
        default:
        {
            return {
                ...state
            }   
        }
    }
}

export default cityReducer;