import { useState } from "react";
import Header from "../../components/Header/header";
import Footer from "../../components/Footer/Footer";


const PageNotFound = ()=>{
    const [category,setCategory] = useState([]);
    return <>
        <div className="bg-img m-0">
            <Header category={category} setCategory={setCategory}/>
            <h1 className="header text-light text-center display-4 m-0">
                Welcome To Satyam Properties
            </h1>    
        </div>
        <div class="d-flex justify-content-center align-items-center properties col-12">
            <div classname="col-12">
                <div classname="d-flex flex-wrap justify-content-center align-items-center">                   
                    <p class="display-1 text-center text-warning">404</p>
                    <h1 class="pb-4 text-muted d-flex align-items-center ">
                        Page Not Found!
                    </h1>
                </div>
            </div>
        </div>
        <Footer />
    </>;
}

export default PageNotFound;