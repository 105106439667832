import "./SpecializedService.css";

const SpecializedService = ({img, header,headerHeighlighted,content,direction=true})=>{
    if(direction)
    {
        return <>
            <div className="specializedService d-flex flex-wrap m-2">
                <div className="col-md-5 col-sm-12 col-12 my-auto">
                    <img src={`images/${img}`} className="img-fluid w-100" alt="" />
                </div>
                <div className="col-md-7 col-12 d-none d-md-flex justify-conten-md-center justify-conten-center  align-items-center flex-wrap">
                    <div className="specializedService">
                        <h1 className="specializedHeader title col-12">
                            {header}
                            <br/>
                            <span className="highlight">{headerHeighlighted}</span>
                        </h1>
                        <p className="specializedText col-10 m-4">
                        {content}
                        </p>
                    </div>
                </div>
                <div className="col-md-5 col-12 d-flex d-md-none justify-conten-md-center justify-conten-center  align-items-center flex-wrap">
                    <h1 className="specializedHeader col-12">
                        {header} <span className="highlight">{headerHeighlighted}</span>
                    </h1>
                    <p className="col-10 m-4">
                        {content}
                    </p>
                </div>
            </div>
        </>;
    }
    else
    {
        return <>
            <div className="specializedService d-flex flex-wrap m-2">
                <div className="col-md-7 col-12 d-none d-md-flex justify-conten-md-center justify-conten-center  align-items-center flex-wrap">
                    <div className="specializedService">
                        <h1 className="specializedHeader title col-12">
                            {header}
                            <br/>
                            <span className="highlight">{headerHeighlighted}</span>
                        </h1>
                        <p className="specializedText col-10 m-4">
                        {content}
                        </p>
                    </div>
                </div>
                <div className="col-md-5 col-sm-12 col-12 my-auto">
                    <img src={`images/${img}`} className="img-fluid w-100" alt="" />
                </div>
                <div className="col-md-5 col-12 d-flex d-md-none justify-conten-md-center justify-conten-center  align-items-center flex-wrap">
                    <h1 className="specializedHeader col-12">
                        {header} <span className="highlight">{headerHeighlighted}</span>
                    </h1>
                    <p className="col-10 m-4">
                        {content}
                    </p>
                </div>
            </div>
        </>;
    }
    
}

export default SpecializedService;