import { useEffect, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./cities.css"
import { removeCity, saveCity } from "../../../actions/admin";

const Cities = ()=>{
    const cities = useSelector(state=>state.cities.cities)
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [cityDisplay,setCityDisplay] = useState(cities);
    const [cityName,setCityName] = useState("");
    const [citySearch,setCitySearch] = useState("");
    const [dropdown,setDropdown] = useState(false);
    const citySearchHandler = (e)=>{
        setCitySearch(e.target.value);
        const regex = new RegExp(e.target.value,"gi");
        const temp = cities.filter((value)=>{
            return value.name.match(regex)===null?false:true
        })
        setCityDisplay([...temp]);
    }
    const cityNameHandler = (e)=>{
        setCityName(e.target.value);
    }
    const save = (e)=>{
        dispatch(saveCity(cityName,navigate))
    }
    const remove = (e)=>{
        dispatch(removeCity(e.target.name,navigate))
    }

    useEffect(()=>{
        setCityDisplay(cities);
    },[cities])

    return <>
    <div className="d-flex city justify-content-center align-items-center">
        <div className="card cityCard">
          <div className="card-body">
            <h1 className="card-title text-center">Cities</h1>
            <div className="d-flex justify-content-center align-items-center dropdown">
                <input type="text" name="citySearch" className="form-control my-1 me-4" placeholder="Search Here" id="citySearch" value={citySearch} onChange={citySearchHandler} />
                <button className="btn btn-success rounded-circle addBtn" onClick={()=>{setDropdown(!dropdown)}}> <IoMdAdd className="fs-2" /> </button>
            </div>
            <div className={`input-group my-4 ${dropdown?"":"d-none"}`}>
                <input type="text" name="city" className="form-control" placeholder="Enter City Name" id="cityName" value={cityName} onChange={cityNameHandler} />
                { cityName && <button className="btn btn-success" onClick={save}>Add</button> }
            </div>
            <ul className="list-group list-group-flush">
                {
                    cityDisplay?cityDisplay.map((value)=><li className="list-group-item list-group-item-action">{value.name} <button className="btn btn-danger" onClick={remove} name={value.name}>Remove</button></li>):""
                }
            </ul>
          </div>
        </div>
    </div>
</>;
}

export default Cities