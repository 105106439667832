import { PropagateLoader } from "react-spinners"
import "./loading.css"

const Loading = ()=>{
    return <div className="loading">
        <h2 className="text-muted text-center">
            <span className="py-2">
                <span className="animate" style={{"--animation-delay":1}}>
                    L
                </span>
                <span className="animate" style={{"--animation-delay":2}} >
                    O
                </span>
                <span className="animate" style={{"--animation-delay":3}} >
                    A
                </span>
                <span className="animate" style={{"--animation-delay":4}} >
                    D
                </span>
                <span className="animate" style={{"--animation-delay":5}} >
                    I
                </span>
                <span className="animate" style={{"--animation-delay":6}} >
                    N
                </span>
                <span className="animate" style={{"--animation-delay":7}} >
                    G
                </span>
            </span>
            <PropagateLoader
                color="#36d7b7"
                size={20}
                cssOverride={
                    {
                        marginTop: '10px',
                        marginLeft: '-15px'
                    }
                }
            />
        </h2>
            
    </div>
}

export default Loading