import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import Property from "./pages/Property/Property";
import PropertyDetails from "./pages/PropertyDetails/PropertyDetails";
import Login from "./pages/Login/Login";
import AdminPanel from "./pages/AdminPanel/AdminPanel";
import store from "./store";
import getUser from "./actions/getUser";
import ProtectedRoute from "./routes/routes";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getCategories } from "./actions/getCategories";
import { getCities } from "./actions/getCities";
import PropertySearch from "./pages/PropertySearch/PropertySearch";
import PageNotFound from "./pages/PageNotFound/PageNotFound";

store.dispatch(getUser());
const App = ()=>{
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(getCategories());
        dispatch(getCities())
    },[dispatch])
    return  <>
            <Routes>
                <Route exact path="/" element={<Home />}></Route>
                <Route exact path="/login" element={<Login />}></Route>
                <Route exact path="/admin/:section/:page" element={<ProtectedRoute />}>
                    <Route exact path="/admin/:section/:page" element={<AdminPanel />} />
                </Route>
                <Route exact path="/admin/:section" element={<ProtectedRoute />}>
                    <Route exact path="/admin/:section" element={<AdminPanel />} />
                </Route>
                <Route exact path="/Property/Search" element={<PropertySearch />}></Route>
                <Route exact path="/Property" element={<Property />}></Route>
                <Route exact path="/Property/:page" element={<Property />}></Route>
                <Route exact path="/PropertyDetails/:id" element={<PropertyDetails />}></Route>
                <Route path="/*" element={<PageNotFound />}></Route>
            </Routes>
        </>
}
export default App;