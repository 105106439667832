import axios from "axios"
import { AREA_REMOVE_FAIL, AREA_REMOVE_REQUEST, AREA_REMOVE_SUCCESS, AREA_SAVE_FAIL, AREA_SAVE_SUCCESS, CATEGORY_REMOVE_FAIL, CATEGORY_REMOVE_REQUEST, CATEGORY_REMOVE_SUCCESS, CATEGORY_SAVE_FAIL, CATEGORY_SAVE_REQUEST, CATEGORY_SAVE_SUCCESS, CITY_REMOVE_FAIL, CITY_REMOVE_REQUEST, CITY_REMOVE_SUCCESS, CITY_SAVE_FAIL, CITY_SAVE_REQUEST, COMPLETE_INQUIRY_FAIL, COMPLETE_INQUIRY_REQUEST, COMPLETE_INQUIRY_SUCCESS, CREATE_PROPERTY_FAIL, CREATE_PROPERTY_REQUEST, CREATE_PROPERTY_SUCCESS, INQUIRY_FAIL, INQUIRY_REQUEST, INQUIRY_SUCCESS, UPDATE_ADMIN_FAIL, UPDATE_ADMIN_REQUEST, UPDATE_ADMIN_SUCCESS, UPDATE_PROPERTY_FAIL, UPDATE_PROPERTY_REQUEST, UPDATE_PROPERTY_SUCCESS } from "../constants/adminConstants"
import { toast } from "react-toastify"
import { getCategories } from "./getCategories"
import { getCities } from "./getCities"
import endpoint from "../utils/endpoint"
import { getAllProperties } from "./getProperties"

export const saveCategory = (category,navigate)=>{
    return async (dispatch)=>{
        try
        {
            dispatch({type:CATEGORY_SAVE_REQUEST})
            const categorySave = await axios.post(endpoint+"/api/satyam/category",{name:category})
            if(categorySave.data.status)
            {
                dispatch({type: CATEGORY_SAVE_SUCCESS, payload: `${categorySave.data.message.name} Added Successfully`});
                dispatch(getCategories())
                toast.success(`${categorySave.data.message.name} Added Successfully`, {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        }
        catch(e)
        {
            dispatch({type: CATEGORY_SAVE_FAIL, payload: e.response.data.message})
            toast.error(e.response.data.message, {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
            if(e.response.data.message==="Please Login First !!")
            {
                return navigate("/login")
            }
        }
    }
}

export const removeCategory = (category,navigate)=>{
    return async (dispatch)=>{
        try
        {
            dispatch({type:CATEGORY_REMOVE_REQUEST})
            const categoryDelete = await (await axios.delete(endpoint+"/api/satyam/category",{data:{name:category}}))
            if(categoryDelete.data.status)
            {
                dispatch({type: CATEGORY_REMOVE_SUCCESS, payload: categoryDelete.data.message});
                dispatch(getCategories())
                toast.success(categoryDelete.data.message, {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        }
        catch(e)
        {
            dispatch({type: CATEGORY_REMOVE_FAIL, payload: e.response.data.message})
            toast.error(e.response.data.message, {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
            if(e.response.data.message==="Please Login First !!")
            {
                return navigate("/login")
            }
        }
    }
}

export const saveCity = (city,navigate)=>{
    return async (dispatch)=>{
        try
        {
            dispatch({type:CITY_SAVE_REQUEST})
            const citySave = await axios.post(endpoint+"/api/satyam/city",{name:city})
            if(citySave.data.status)
            {
                dispatch({type: CATEGORY_SAVE_SUCCESS, payload: `${citySave.data.message.name} Added Successfully`});
                dispatch(getCities())
                toast.success(`${citySave.data.message.name} Added Successfully`, {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        }
        catch(e)
        {
            dispatch({type: CITY_SAVE_FAIL, payload: e.response.data.message})
            toast.error(e.response.data.message, {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
            if(e.response.data.message==="Please Login First !!")
            {
                return navigate("/login")
            }
        }
    }
}

export const removeCity = (city,navigate)=>{
    return async (dispatch)=>{
        try
        {
            dispatch({type:CITY_REMOVE_REQUEST})
            const cityDelete = await (await axios.delete(endpoint+"/api/satyam/city",{data:{name:city}}))
            if(cityDelete.data.status)
            {
                dispatch({type: CITY_REMOVE_SUCCESS, payload: cityDelete.data.message});
                dispatch(getCities())
                toast.success(`${cityDelete.data.message.name} Removed Successfully`, {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        }
        catch(e)
        {
            dispatch({type: CITY_REMOVE_FAIL, payload: e.response.data.message})
            toast.error(e.response.data.message, {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
            if(e.response.data.message==="Please Login First !!")
            {
                return navigate("/login")
            }
        }
    }
}

export const saveArea = (area,city,navigate)=>{
    return async (dispatch)=>{
        try
        {
            dispatch({type:AREA_SAVE_SUCCESS})
            const areaSave = await axios.post(endpoint+"/api/satyam/area",{area,city})
            if(areaSave.data.status)
            {
                dispatch({type: AREA_SAVE_SUCCESS, payload: `${area} Added Successfully`});
                dispatch(getCities())
                toast.success(`${area} Added Successfully`, {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        }
        catch(e)
        {
            dispatch({type: AREA_SAVE_FAIL, payload: e.response.data.message})
            toast.error(e.response.data.message, {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
            if(e.response.data.message==="Please Login First !!")
            {
                return navigate("/login")
            }
        }
    }
}

export const removeArea = (area,city,navigate)=>{
    return async (dispatch)=>{
        try
        {
            dispatch({type:AREA_REMOVE_REQUEST})
            const areaDelete = await (await axios.delete(endpoint+"/api/satyam/area",{data:{area,city}}))
            if(areaDelete.data.status)
            {
                dispatch({type: AREA_REMOVE_SUCCESS, payload: areaDelete.data.message});
                dispatch(getCities())
                toast.success(`${area} Removed Successfully`, {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        }
        catch(e)
        {
            dispatch({type: AREA_REMOVE_FAIL, payload: e.response.data.message})
            toast.error(e.response.data.message, {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
            if(e.response.data.message==="Please Login First !!")
            {
                return navigate("/login")
            }
        }
    }
}

export const getInquiries = ()=>{
    return async (dispatch)=>{
        try
        {
            dispatch({type:INQUIRY_REQUEST});
            const inquiries = await axios.get(endpoint+"/api/satyam/inquiry")
            if(inquiries.status)
            {
                dispatch({type:INQUIRY_SUCCESS,payload:inquiries.data});
            }
            else
            {
                dispatch({type:INQUIRY_FAIL})
                toast.error(inquiries.data.message, {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        }
        catch(e)
        {
            toast.error(e.response.data.message, {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
            dispatch({type:INQUIRY_FAIL})
        }
    }
}


export const completeInquiry = ({id})=>{
    return async (dispatch)=>{
        try
        {
            dispatch({type:COMPLETE_INQUIRY_REQUEST});
            const inquiries = await axios.patch(endpoint+`/api/satyam/inquiry/${id}`)
            if(inquiries.status)
            {
                dispatch({type:COMPLETE_INQUIRY_SUCCESS});
            }
            else
            {
                dispatch({type:COMPLETE_INQUIRY_FAIL})
                toast.error(inquiries.data.message, {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        }
        catch(e)
        {
            toast.error(e.response.data.message, {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
            dispatch({type:INQUIRY_FAIL})
        }
    }
}

export const propertyCreate = ({propertyName,category,size,unit,area,city,price,images,featureOptionsSelected,description,ytb,page})=>{
    return async (dispatch)=>{
        try{
            dispatch({type:CREATE_PROPERTY_REQUEST});
            const property = await axios.post(endpoint+"/api/satyam/property",{
                "name" : propertyName,
                "category" : category,
                "price" : price,
                "area" : area,
                "city" : city,
                "size" : size,
                "sizeUnit" : unit,
                "description" : description,
                "facilities" : featureOptionsSelected,
                "images":images,
                "youtube":ytb
            },{headers:{"Content-Type":"multipart/form-data"}})
            if(property)
            {
                dispatch({type:CREATE_PROPERTY_SUCCESS});
                dispatch(getAllProperties({page}))
                toast.success(`Property Created Successfully`, {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
            else
            {
                dispatch({type:CREATE_PROPERTY_FAIL,payload:"Internal Server Error"});
                toast.error("Failed To Create Property", {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }


        }
        catch(e)
        {
            toast.error(e.response.data.message, {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
            dispatch({type:CREATE_PROPERTY_FAIL,payload:e.response.data.message})
        }
    }
}

export const propertyUpdate = ({id,propertyName,category,size,unit,area,city,price,images,featureOptionsSelected,description,ytb,page,setFileUpdated})=>{
    return async (dispatch)=>{
        try{
            dispatch({type:UPDATE_PROPERTY_REQUEST});
            const property = await axios.post(endpoint+`/api/satyam/property/${id}`,{
                id,
                "name" : propertyName,
                "category" : category,
                "price" : price,
                "area" : area,
                "city" : city,
                "size" : size,
                "sizeUnit" : unit,
                "description" : description,
                "facilities" : featureOptionsSelected,
                "images":images,
                "youtube":ytb
            },{headers:{"Content-Type":"multipart/form-data"}})
            if(property)
            {
                setFileUpdated(false);
                dispatch({type:UPDATE_PROPERTY_SUCCESS});
                dispatch(getAllProperties({page}))
                toast.success(`Property Updated Successfully`, {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
            else
            {
                dispatch({type:UPDATE_PROPERTY_FAIL,payload:"Internal Server Error"});
                toast.error("Failed To Update Property", {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }


        }
        catch(e)
        {
            toast.error(e.response.data.message, {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
            dispatch({type:UPDATE_PROPERTY_FAIL,payload:e.response.data.message})
        }
    }
}

export const updateAdmin=({fname,lname})=>{
    return async (dispatch)=>{
        try
        {
            dispatch({type:UPDATE_ADMIN_REQUEST})
            const user = await axios.post(endpoint+"/api/satyam/admin/",{data:{fname,lname}})
            if(user)
            {
                dispatch({type:UPDATE_ADMIN_SUCCESS,payload:user.data.message})
            }
            else
            {
                dispatch({type:UPDATE_ADMIN_FAIL,payload:user.message})
            }
        }
        catch(e)
        {
            toast.error(e.response.data.message, {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
            dispatch({type:UPDATE_ADMIN_FAIL,payload:e.response.data.message})
        }
    }
}