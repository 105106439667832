import { CATEGORY_SAVE_FAIL, CATEGORY_SAVE_REQUEST, CATEGORY_SAVE_SUCCESS, CREATE_PROPERTY_FAIL, CREATE_PROPERTY_REQUEST, CREATE_PROPERTY_SUCCESS, UPDATE_PROPERTY_FAIL, UPDATE_PROPERTY_REQUEST, UPDATE_PROPERTY_SUCCESS } from "../constants/adminConstants";

const initialState = {
    loading : false
}

const adminReducer = (status=initialState,action)=>{
    switch(action.type)
    {
        case CREATE_PROPERTY_REQUEST:
        case UPDATE_PROPERTY_REQUEST:
        case CATEGORY_SAVE_REQUEST:{
            return {
                ...initialState,
                loading:true
            }
        }
        case CREATE_PROPERTY_SUCCESS:
        case UPDATE_PROPERTY_SUCCESS:          
        case CATEGORY_SAVE_SUCCESS:{
            return {
                loading:false,
                message:action.payload
            }
        }
        case CREATE_PROPERTY_FAIL:
        case UPDATE_PROPERTY_FAIL:
        case CATEGORY_SAVE_FAIL:{
            return {
                ...status,
                loading:false,
                error:action.payload
            }
        }
        default :
        {
            return {
                ...status
            }
        }
    }
}

export default adminReducer