import heic2any from "heic2any";

async function convertHEIC (event){
    let blobs = []
    for(let i=0;i<event.target.files.length;i++)
    {
        if(event.target.files[i] && event.target.files[i].name.includes(".HEIC")){
            let blobURL = URL.createObjectURL(event.target.files[i]);
            let blobRes = await fetch(blobURL)
            let blob = await blobRes.blob()
            let conversionResult = await heic2any({ blob })
            blobs.push(conversionResult);
        }      
        else
        {
            blobs.push(event.target.files[i])
        }
    }
    return blobs
     
};

export default convertHEIC;