import "./userProfile.css"
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { updateAdmin } from "../../../actions/admin";

const UserProfile = ()=>{
    const user = useSelector(state=>state.user.user);
    const [fname,setFname] = useState(user.fname);
    const [lname,setLname] = useState(user.lname);
    const dispatch = useDispatch();
    const inputHandler = (e)=>{
        if(e.target.id==="fname")
        {
            setFname(e.target.value);
        }
        else
        {
            setLname(e.target.value);
        }
    }

    const submitHandler = (e)=>{
        dispatch(updateAdmin({fname,lname}));
    }

    return <>
        <div className="content">
            <div className="d-flex align-items-center">
                <img src="/images/logo.PNG" alt="Profile" className="w-25 m-4 shadow rounded-circle"  />
                <div className="d-flex flex-column align-items-start">
                    <h4 className="display-1 profileName p-0 m-0">{user.fname}</h4>
                    <p className="text-muted fs-5">{user.email}</p>
                </div>
            </div>
            <hr className="mx-4"/>
            <div className="userProfile">
                <div className="m-4">
                    <label htmlFor="fname" className="text-muted fs-5">First Name</label>
                    <input
                        type="text"
                        className="form-control p-3"
                        id="fname"
                        placeholder="Enter Your First Name"
                        required
                        value={fname}
                        onChange={inputHandler}
                    />
                </div>
                <div className="m-4">
                    <label htmlFor="fname" className="text-muted fs-5">Last Name</label>
                    <input
                        type="text"
                        className="form-control p-3"
                        id="lname"
                        placeholder="Enter Your Last Name"
                        value={lname}
                        onChange={inputHandler}
                        required
                    />
                </div>
                <div className="m-4 text-center">
                    <button className="btn btn-lg px-4 submitBtn" onClick={submitHandler}>Save</button>
                </div>
            </div>
        </div>
    </>;
}

export default UserProfile;