import axios from "axios";
import { LOGIN_FAIL, LOGOUT_REQUEST, LOGOUT_SUCCESS } from "../constants/loginConstant"
import endpoint from "../utils/endpoint"

const logout = ()=>{
    return async (dispatch)=>{
        try
        {
            dispatch({type:LOGOUT_REQUEST});
            const user = await axios.post(endpoint+"/api/satyam/admin/logout");
            if(user.status)
            {
                dispatch({type:LOGOUT_SUCCESS,payload:user.message});
                window.location.href="/";
            }
            else
            {
                dispatch({type:LOGIN_FAIL,payload:user.message});
            }
        }
        catch(e)
        {
        }
    }
}

export default logout;