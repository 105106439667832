import axios from "axios";
import { INQUIRY_FORM_SUBMIT_FAIL, INQUIRY_FORM_SUBMIT_REQUEST, INQUIRY_FORM_SUBMIT_SUCCESS } from "../constants/inquiryFormConstants"
import { toast } from "react-toastify";
import endpoint from "../utils/endpoint"

const inquiryFormSubmit = ({id,fname,lname,email,contact})=>{
    return async (dispatch)=>{
        try
        {
            dispatch({type:INQUIRY_FORM_SUBMIT_REQUEST});
            const inquiryForm = await axios.post(`${endpoint}/api/satyam/inquiry/${id}`,{fName:fname,lName:lname,email:email,mobile:contact})
            if(inquiryForm.data.status)
            {
                toast.success("Your Inquiry is Submitted Successfully", {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
                dispatch({type:INQUIRY_FORM_SUBMIT_SUCCESS});
            }
            else
            {
                dispatch({type:INQUIRY_FORM_SUBMIT_FAIL});
            }
        }
        catch(e)
        {
            dispatch({type:INQUIRY_FORM_SUBMIT_FAIL})
            toast.error(e.response.data.message, {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
        }
    }
}

export default inquiryFormSubmit;